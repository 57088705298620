import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

function FooterComponent() {
  return (
    <Box
      sx={{
        mt: 5,
        p: 4,
        backgroundColor: '#000',
        color: '#fff',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {/* Texto en la izquierda */}
      <Box>
      <Link to="/" style={{ color: '#fff', textDecoration: 'none' }}>
        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
          BUSINESS SOLUTIONS
        </Typography>
      </Link><br/>
        <Link to="/privacy" style={{ color: '#fff', textDecoration: 'underline' }}>
          <Typography variant="h8" mb={2}>
            Política de Privacidad
          </Typography>
        </Link><br/><br/>
        <Link to="/tyc" style={{ color: '#fff', textDecoration: 'underline' }}>
          <Typography variant="h8" mb={2}>
            Términos y Condiciones
          </Typography>
        </Link>
      </Box>

      {/* Texto en la derecha */}
      <Box sx={{ textAlign: 'left' }}>
        <Typography variant="h6" mb={2}>
          Contacto:
        </Typography>
        <Typography variant="h8" mb={2}>info@solutionbusiness.tech</Typography><br/><br/>
        <Link to="/support" style={{ color: '#fff', textDecoration: 'underline' }}>
          <Typography variant="h8" mb={2}>
            Soporte
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

export default FooterComponent;
