import React from 'react';
import { Card, CardContent, CardMedia, Typography, Button, Box } from '@mui/material';

function PlanCard({ title, price, details,  width, image, link}) {
  return (
    <Card
      sx={{
        width: width,
        height: 490,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '16px',
        overflow: 'hidden',
      }}
    >
      {/* Image at the top */}
      <CardMedia
        component="img"
        height="25%"
        image={image}
        alt="Plan Image"
      />

      {/* Content and Button with full black background */}
      <CardContent
        sx={{
          flexGrow: 1,
          backgroundColor: '#000',
          color: '#fff',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        {/* Title */}
        <Typography variant="h6" gutterBottom sx={{ fontWeight: 600 }} ml={1}>
          {title}
        </Typography>

        {/* Details */}
        <Box sx={{ marginBottom: 'auto' }}>
          {details.map((detail, index) => (
            <Typography
              variant="body2"
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontWeight: 400,
                marginBottom: '12px', // Add margin for line breaks
              }}
              ml={2}
            >
              •&nbsp;{detail}
            </Typography>
          ))}
        </Box>

        {/* Button at the bottom */}
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#00BFFF',
            borderRadius: '50px',
            width: '90%',
            color: '#000',
          }}
          onClick={() => window.location.href = link}
        >
          {price}
        </Button>
        </Box>
      </CardContent>
    </Card>
  );
}

export default PlanCard;
