import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, Button, AppBar, Toolbar, Menu, MenuItem } from '@mui/material';
import PlanCard from '../components/PlanCard';
import WhatsAppPlanComponent from '../components/WhatsAppPlanComponent';
import FooterComponent from '../components/FooterComponent';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Paper from '@mui/material/Paper';
import { db } from '../firebase';
import { collection, getDoc, doc, query, where, getDocs, addDoc  } from 'firebase/firestore';
import { Link } from 'react-router-dom'

const Home = () => {
  const [currentView, setCurrentView] = useState('body');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [user, setUser] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramValue = queryParams.get('param');
    if (paramValue) {
      // Mostrar una alerta con el valor del parámetro
      alert(`Pago realizado por: ${paramValue} USD... Dentro de poco nos comunicaremos contigo`);
  
      // Convertir paramValue a número entero
      const paramValueAsNumber = parseInt(paramValue, 10);
  
      // Obtener la fecha actual
      const currentDate = new Date();
  
      // Verificar si hay un usuario en localStorage
      const storedUserDocId = localStorage.getItem('userDocId');
      if (storedUserDocId) {
        // Agregar un nuevo pago a la subcolección 'pagos' del usuario
        const addPayment = async () => {
          try {
            const userDocRef = doc(db, 'users', storedUserDocId);
            await addDoc(collection(userDocRef, 'pagos'), {
              amount: paramValueAsNumber,
              date: currentDate,
            });
            console.log('Pago agregado exitosamente a la subcolección.');
          } catch (error) {
            console.error('Error al agregar el pago:', error);
          }
        };
        addPayment();
      }
  
      // Eliminar el parámetro de la URL después de cerrar la alerta
      queryParams.delete('param');
      // Actualizar la URL sin recargar la página
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  
    // Verificar si hay un usuario en localStorage
    const storedUserDocId = localStorage.getItem('userDocId');
    if (storedUserDocId) {
      // Cargar datos del documento de Firestore si existe el ID en localStorage
      const fetchUserData = async () => {
        try {
          const userDoc = await getDoc(doc(db, 'users', storedUserDocId));
          if (userDoc.exists()) {
            setUser(userDoc.data());
            setCurrentView('body');
          } else {
            alert('Usuario no encontrado, vuelva a iniciar sesión');
            localStorage.removeItem('userDocId');
          }
        } catch (error) {
          console.error('Error al obtener los datos del usuario:', error);
          alert('Error al obtener los datos del usuario.');
        }
      };
      fetchUserData();
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Consultar la colección "users" para buscar una coincidencia
      const q = query(collection(db, 'users'), where('name', '==', email), where('pass', '==', password));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Si hay coincidencia, obtener el primer documento encontrado
        const userDoc = querySnapshot.docs[0];
        setUser(userDoc.data());

        if (rememberMe) {
          // Guardar la dirección (ID) del documento en localStorage
          localStorage.setItem('userDocId', userDoc.id);
        }

        setCurrentView('body');
      } else {
        alert('Usuario no encontrado, revisa tus datos.');
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      alert('Error al iniciar sesión: ' + error.message);
    }
  };
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Container disableGutters maxWidth={false} sx={{ padding: 0, margin: 0 }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}> {/* Fondo ligeramente transparente */}
      <Toolbar>
        <Typography 
          variant="h6" 
          component="div" 
          sx={{ flexGrow: 1, color: '#fff' }} // Cambia a azul neón en hover
          onClick={() => setCurrentView('body')}
        >
        Business Solution
        </Typography>
        <Box sx={{ flexGrow: 0 }}>
          <Typography 
            variant="h6" 
            component="div" 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{ cursor: 'pointer', color: isHovered ? '#00BFFF' : '#fff' }}
            onClick={(event) => user ? handleOpenUserMenu(event) : setCurrentView('login')}
          >
            {user ? user.name : 'Portal Clientes'}
          </Typography>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key='loginout' onClick={() => { 
              handleCloseUserMenu(); 
              localStorage.removeItem('userDocId');
              setUser(null);
            }}>
              <Typography textAlign="center">Cerrar sesión</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
      </Box>

      {/* Renderizado condicional basado en la vista actual */}
      {currentView === 'body' && (
        <>
        {user === null && (
          <Box
            sx={{
              marginTop:'60px',
              height: '93vh',
              width: '100%',
              backgroundImage: 'url(/images/image7.png)',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />)}
          <Typography
            variant="h4"
            align="center"
            sx={{ marginBottom: '32px', marginTop: '85px', fontWeight: 'bold' }}
          >
            Asesorías personalizadas
          </Typography>

          <Grid container spacing={4} justifyContent="center">
            <Grid item>
              <PlanCard
                image="/images/image9.png"
                title="Plan A"
                price="25 USD"
                details={[
                  'Inducción y entendimiento de la actualidad tu empresa.',
                  'Serie de sugerencias para una implementación rápida',
                  'Duración: 30 min',
                ]}
                width={290}
                link={user?'https://buy.stripe.com/28o6q01s72YO3gQ3ck?prefilled_email='+user.name.replace('@', '%40'):'https://buy.stripe.com/28o6q01s72YO3gQ3ck'}
              />
            </Grid>
            <Grid item>
              <PlanCard
                image="/images/image11.png"
                title="Plan B"
                price="50 USD"
                details={[
                  'Inducción y entendimiento de la actualidad tu empresa.',
                  'Intercambio de información de su competencia',
                  'Seguimiento de objetivos corto y largo plazo',
                  'Duración: 30 min',
                ]}
                width={290}
                link={user?'https://buy.stripe.com/dR68y8gn19nc6t25km?prefilled_email='+user.name.replace('@', '%40'):'https://buy.stripe.com/dR68y8gn19nc6t25km'}
              />
            </Grid>
            <Grid item>
              <PlanCard
                image="/images/image10.png"
                title="Plan C"
                price="100 USD"
                details={[
                  'Inducción y entendimiento de la actualidad tu empresa.',
                  'Intercambio de información de su competencia',
                  'Serie de sugerencias para una implementación rápida acorde a objetivos a mediano plazo',
                  'Seguimiento y corrección de objetivos a corto y mediano plazo',
                ]}
                width={290}
                link={user?'https://buy.stripe.com/aEUg0A8UzeHw5oYaEH?prefilled_email='+user.name.replace('@', '%40'):'https://buy.stripe.com/aEUg0A8UzeHw5oYaEH'}
              />
            </Grid>
          </Grid>          
          <Typography
            variant="h4"
            align="center"
            sx={{ marginBottom: '32px',  marginTop: '85px', fontWeight: 'bold' }}
          >
            Paquetes personalizados
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item>
              <PlanCard
                image="/images/image14.png"
                title="Plan D"
                price="400 USD"
                details={[
                  'Dirección de Marketing Digital con el equipo de su empresa',
                  'Implementación de herramientas tecnológicas de coordinación interna',
                  'Informe mensual de rendimiento',
                ]}
                width={290}
                link={user?'https://buy.stripe.com/00g29Kb2Harg8BacMR?prefilled_email='+user.name.replace('@', '%40'):'https://buy.stripe.com/00g29Kb2Harg8BacMR'}
              />
            </Grid>
            <Grid item>
              <PlanCard
                image="/images/image16.png"
                title="Plan E"
                price="700 USD"
                details={[
                  'Dirección de Marketing Digital con el equipo de su empresa',
                  'Implementación de herramientas tecnológicas de coordinación interna',
                  'Dirección en el levantamiento de herramientas de contacto con sus clientes',
                  'Informe mensual de rendimiento',
                  'Plan de acción mes siguiente',
                ]}
                width={290}
                link={user?'https://buy.stripe.com/9AQ8y81s78j86t26ou?prefilled_email='+user.name.replace('@', '%40'):'https://buy.stripe.com/9AQ8y81s78j86t26ou'}
              />
            </Grid>
            <Grid item>
              <PlanCard
                image="/images/image15.png"
                title="Plan F"
                price="1000 USD"
                details={[
                  'Asesoría completa para armar su propio equipo de Marketing',
                  'Asesoría completa para armar su propio equipo de Tecnología',
                  'Asesoría completa para armar su propio equipo de Innovación',
                  'Seguimiento continuo con la empresa sobre el proceso de armado de equipos',
                ]}
                width={290}
                link={user?'https://buy.stripe.com/14k8y86MrargaJi7sz?prefilled_email='+user.name.replace('@', '%40'):'https://buy.stripe.com/14k8y86MrargaJi7sz'}
              />
            </Grid>
          </Grid>
        {user === null && (
        <>
          <Typography
            variant="h4"
            align="center"
            sx={{ marginBottom: '32px',  marginTop: '85px', fontWeight: 'bold' }}
          >
            Desarrollo de software
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item>
              <PlanCard
                image="/images/image17.png"
                title="Crea tu WEB"
                price="AGENDA TU REUNIÓN"
                details={[
                  'Levantamiento de objetivos con la intención de desarrollar o rediseñar una web',
                  'Desarrollo completo de la web, panel de control interno y demás funcionalidades',
                  'Entrega de todos los accesos necesarios para un manejo independiente de todos los elementos de su web',
                ]}
                width={350}
              />
            </Grid>
            <Grid item>
              <PlanCard
                image="/images/image18.png"
                title="Crea tu APP"
                price="AGENDA TU REUNIÓN"
                details={[
                  'Levantamiento de objetivos con la intención de desarrollar o corregir una aplicación móvil',
                  'Desarrollo completo de la aplicación móvil, panel de control interno y demás funcionalidades',
                  'Entrega de todos los accesos necesarios para un manejo independiente de todos los elementos de su web',
                ]}
                width={350}
              />
            </Grid>
          </Grid>
        </>
        )}
          <Typography
            variant="h4"
            align="center"
            sx={{ marginBottom: '32px',  marginTop: '85px', fontWeight: 'bold' }}
          >
            Mensajería masiva
          </Typography>
          <WhatsAppPlanComponent ml={3} mr={3} link={user?'https://buy.stripe.com/5kAg0A8UzdDs04E6ox?prefilled_email='+user.name.replace('@', '%40'):'https://buy.stripe.com/5kAg0A8UzdDs04E6ox'}/>
          <FooterComponent />
        </>
      )}
{currentView === 'login' && (
  <>
  <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/images/image20.png)',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'left',
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', // Centrado verticalmente
        }}
      >
        <Box
          sx={{
            padding: 4, // Ajustado para mejor visualización
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleLogin}>
            <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold' }}>
              Bienvenido
            </Typography>
            <Typography component="h1" variant="h6">
              Ingresa tu usuario y contraseña
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              placeholder="Usuario"
              name="user"
              autoComplete="user"
              autoFocus
              sx={{
                backgroundColor: '#000',
                borderRadius: '12px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fff',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              sx={{
                backgroundColor: '#000',
                borderRadius: '12px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fff',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
          control={
            <Checkbox 
              value="remember" 
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)} // Actualiza el estado del checkbox
              sx={{
                color: '#000', 
                '&.Mui-checked': {
                  color: '#000',
                },
              }} 
            />
          }
          label="Recordarme"
        />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                color: '#000',
                fontWeight: 'bold',
              }}
            >
              INGRESAR
            </Button>
          </Box>
          <Typography>¿Tienes problemas para ingresar?</Typography>
          <Typography>
            <Link to="/support">
              info@solutionbusiness.tech
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </>
)}
    </Container>
  );
}

export default Home;
