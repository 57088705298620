import React from 'react';
import { Box, Typography, Button} from '@mui/material';

// Componente para manejar el tooltip del slid

function WhatsAppPlanComponent({ link}) {


  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          width: 900,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#000',
          borderRadius: '18px',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            width: 340,
            height: 350,
            backgroundImage: 'url(/images/image13.png)',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Box sx={{ width: 560, marginLeft: 5, color: '#fff' }}>
          <Typography variant="h5" gutterBottom>
            Plan WhatsAPP
          </Typography>
          
          <Button
            variant="contained"
            sx={{
              mt: 8,
              backgroundColor: '#00BFFF',
              width: '90%',
              borderRadius: '20px',
              fontWeight: 'bold',
              color: '#000',
            }}
            onClick={() => window.location.href = link}
          >
            Cotizar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default WhatsAppPlanComponent;
