// pages/SupportPage.js
import './PrivacyPage.css'; // Reutilizamos el archivo de estilos
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, Button, AppBar, Toolbar, Menu, MenuItem } from '@mui/material';
import FooterComponent from '../components/FooterComponent';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { db } from '../firebase';
import { collection, getDoc, doc, query, where, getDocs, addDoc  } from 'firebase/firestore';

const SupportPage = () => {
  
  const [currentView, setCurrentView] = useState('body');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [user, setUser] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramValue = queryParams.get('param');
    if (paramValue) {
      // Mostrar una alerta con el valor del parámetro
      alert(`Pago realizado por: ${paramValue} USD... Dentro de poco nos comunicaremos contigo`);
  
      // Convertir paramValue a número entero
      const paramValueAsNumber = parseInt(paramValue, 10);
  
      // Obtener la fecha actual
      const currentDate = new Date();
  
      // Verificar si hay un usuario en localStorage
      const storedUserDocId = localStorage.getItem('userDocId');
      if (storedUserDocId) {
        // Agregar un nuevo pago a la subcolección 'pagos' del usuario
        const addPayment = async () => {
          try {
            const userDocRef = doc(db, 'users', storedUserDocId);
            await addDoc(collection(userDocRef, 'pagos'), {
              amount: paramValueAsNumber,
              date: currentDate,
            });
            console.log('Pago agregado exitosamente a la subcolección.');
          } catch (error) {
            console.error('Error al agregar el pago:', error);
          }
        };
        addPayment();
      }
  
      // Eliminar el parámetro de la URL después de cerrar la alerta
      queryParams.delete('param');
      // Actualizar la URL sin recargar la página
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  
    // Verificar si hay un usuario en localStorage
    const storedUserDocId = localStorage.getItem('userDocId');
    if (storedUserDocId) {
      // Cargar datos del documento de Firestore si existe el ID en localStorage
      const fetchUserData = async () => {
        try {
          const userDoc = await getDoc(doc(db, 'users', storedUserDocId));
          if (userDoc.exists()) {
            setUser(userDoc.data());
            setCurrentView('body');
          } else {
            alert('Usuario no encontrado, vuelva a iniciar sesión');
            localStorage.removeItem('userDocId');
          }
        } catch (error) {
          console.error('Error al obtener los datos del usuario:', error);
          alert('Error al obtener los datos del usuario.');
        }
      };
      fetchUserData();
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Consultar la colección "users" para buscar una coincidencia
      const q = query(collection(db, 'users'), where('name', '==', email), where('pass', '==', password));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Si hay coincidencia, obtener el primer documento encontrado
        const userDoc = querySnapshot.docs[0];
        setUser(userDoc.data());

        if (rememberMe) {
          // Guardar la dirección (ID) del documento en localStorage
          localStorage.setItem('userDocId', userDoc.id);
        }

        setCurrentView('body');
      } else {
        alert('Usuario no encontrado, revisa tus datos.');
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      alert('Error al iniciar sesión: ' + error.message);
    }
  };
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Container disableGutters maxWidth={false} sx={{ padding: 0, margin: 0 }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}> {/* Fondo ligeramente transparente */}
        <Toolbar>
      <Link to="/" sx={{flexGrow: 1}} style={{ color: '#fff', textDecoration: 'none' }}>
        <Typography 
          variant="h6" 
          component="div" 
          sx={{ color: '#fff' }} // Cambia a azul neón en hover
          onClick={() => setCurrentView('body')}
        >
          Business Solution
        </Typography>
      </Link>
        <Box sx={{ flexGrow: 0 }}>
          <Typography 
            variant="h6" 
            component="div" 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{ cursor: 'pointer', color: isHovered ? '#00BFFF' : '#fff' }}
            onClick={(event) => user ? handleOpenUserMenu(event) : setCurrentView('login')}
          >
            {user ? user.name : 'Portal Clientes'}
          </Typography>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key='loginout' onClick={() => { 
              handleCloseUserMenu(); 
              localStorage.removeItem('userDocId');
              setUser(null);
            }}>
              <Typography textAlign="center">Cerrar sesión</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
      </Box>

      {/* Renderizado condicional basado en la vista actual */}
      {currentView === 'body' && (
        <>
    <div className="privacy-container">
      <h1 className="privacy-title">Soporte</h1>
      <p>Si tienes alguna pregunta o necesitas asistencia, no dudes en contactarnos a través de los siguientes medios:</p>

      <h2>Correo Electrónico</h2>
      <p>
        Puedes enviarnos un correo electrónico a{' '}
        <a href="mailto:info@solutionbusiness.tech">info@solutionbusiness.tech</a>.
      </p>

      <h2>Teléfono</h2>
      <p>
        También puedes llamarnos o enviarnos un mensaje de WhatsApp al{' '}
        <a href="tel:+59176811887">+591 76811887</a>.
      </p>

      <h2>Dirección</h2>
      <p>Nuestra oficina está ubicada en:</p>
      <address>
        Barrio Ramafa,<br />
        Calle Piriti 114,<br />
        Santa Cruz de la Sierra.
      </address>
    </div>
              <FooterComponent />
        </>
      )}
{currentView === 'login' && (
  <>
  <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/images/image20.png)',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'left',
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', // Centrado verticalmente
        }}
      >
        <Box
          sx={{
            padding: 4, // Ajustado para mejor visualización
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleLogin}>
            <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold' }}>
              Bienvenido
            </Typography>
            <Typography component="h1" variant="h6">
              Ingresa tu usuario y contraseña
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              placeholder="Usuario"
              name="user"
              autoComplete="user"
              autoFocus
              sx={{
                backgroundColor: '#000',
                borderRadius: '12px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fff',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              sx={{
                backgroundColor: '#000',
                borderRadius: '12px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fff',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
          control={
            <Checkbox 
              value="remember" 
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)} // Actualiza el estado del checkbox
              sx={{
                color: '#000', 
                '&.Mui-checked': {
                  color: '#000',
                },
              }} 
            />
          }
          label="Recordarme"
        />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                color: '#000',
                fontWeight: 'bold',
              }}
            >
              INGRESAR
            </Button>
          </Box>
          <Typography>¿Tienes problemas para ingresar?</Typography>
          <Typography>
            <Link href="#" variant="body2">
              info@solutionbusiness.tech
            </Link>
          </Typography>
          <Typography>
            <Link href="#" variant="body2">
              WhatsApp +1 000 00 000
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </>
)}
    </Container>
  );
};

export default SupportPage;
