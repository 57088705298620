// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBPoSUnbvvhwy0TpjWPGafPBeeE8HrenjE",
  authDomain: "business-solution-cde2a.firebaseapp.com",
  projectId: "business-solution-cde2a",
  storageBucket: "business-solution-cde2a.appspot.com",
  messagingSenderId: "435353547501",
  appId: "1:435353547501:web:775b4122dcc2af75ce92e4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
