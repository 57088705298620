// pages/TermsPage.js
import './PrivacyPage.css'; // Reutilizamos el archivo de estilos
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, Button, AppBar, Toolbar, Menu, MenuItem } from '@mui/material';
import FooterComponent from '../components/FooterComponent';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { db } from '../firebase';
import { collection, getDoc, doc, query, where, getDocs, addDoc  } from 'firebase/firestore';

const TermsPage = () => {
  
  const [currentView, setCurrentView] = useState('body');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [user, setUser] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramValue = queryParams.get('param');
    if (paramValue) {
      // Mostrar una alerta con el valor del parámetro
      alert(`Pago realizado por: ${paramValue} USD... Dentro de poco nos comunicaremos contigo`);
  
      // Convertir paramValue a número entero
      const paramValueAsNumber = parseInt(paramValue, 10);
  
      // Obtener la fecha actual
      const currentDate = new Date();
  
      // Verificar si hay un usuario en localStorage
      const storedUserDocId = localStorage.getItem('userDocId');
      if (storedUserDocId) {
        // Agregar un nuevo pago a la subcolección 'pagos' del usuario
        const addPayment = async () => {
          try {
            const userDocRef = doc(db, 'users', storedUserDocId);
            await addDoc(collection(userDocRef, 'pagos'), {
              amount: paramValueAsNumber,
              date: currentDate,
            });
            console.log('Pago agregado exitosamente a la subcolección.');
          } catch (error) {
            console.error('Error al agregar el pago:', error);
          }
        };
        addPayment();
      }
  
      // Eliminar el parámetro de la URL después de cerrar la alerta
      queryParams.delete('param');
      // Actualizar la URL sin recargar la página
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  
    // Verificar si hay un usuario en localStorage
    const storedUserDocId = localStorage.getItem('userDocId');
    if (storedUserDocId) {
      // Cargar datos del documento de Firestore si existe el ID en localStorage
      const fetchUserData = async () => {
        try {
          const userDoc = await getDoc(doc(db, 'users', storedUserDocId));
          if (userDoc.exists()) {
            setUser(userDoc.data());
            setCurrentView('body');
          } else {
            alert('Usuario no encontrado, vuelva a iniciar sesión');
            localStorage.removeItem('userDocId');
          }
        } catch (error) {
          console.error('Error al obtener los datos del usuario:', error);
          alert('Error al obtener los datos del usuario.');
        }
      };
      fetchUserData();
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Consultar la colección "users" para buscar una coincidencia
      const q = query(collection(db, 'users'), where('name', '==', email), where('pass', '==', password));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Si hay coincidencia, obtener el primer documento encontrado
        const userDoc = querySnapshot.docs[0];
        setUser(userDoc.data());

        if (rememberMe) {
          // Guardar la dirección (ID) del documento en localStorage
          localStorage.setItem('userDocId', userDoc.id);
        }

        setCurrentView('body');
      } else {
        alert('Usuario no encontrado, revisa tus datos.');
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      alert('Error al iniciar sesión: ' + error.message);
    }
  };
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Container disableGutters maxWidth={false} sx={{ padding: 0, margin: 0 }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}> {/* Fondo ligeramente transparente */}
        <Toolbar>
      <Link to="/" sx={{flexGrow: 1}} style={{ color: '#fff', textDecoration: 'none' }}>
        <Typography 
          variant="h6" 
          component="div" 
          sx={{ color: '#fff' }} // Cambia a azul neón en hover
          onClick={() => setCurrentView('body')}
        >
          Business Solution
        </Typography>
      </Link>
        <Box sx={{ flexGrow: 0 }}>
          <Typography 
            variant="h6" 
            component="div" 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{ cursor: 'pointer', color: isHovered ? '#00BFFF' : '#fff' }}
            onClick={(event) => user ? handleOpenUserMenu(event) : setCurrentView('login')}
          >
            {user ? user.name : 'Portal Clientes'}
          </Typography>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key='loginout' onClick={() => { 
              handleCloseUserMenu(); 
              localStorage.removeItem('userDocId');
              setUser(null);
            }}>
              <Typography textAlign="center">Cerrar sesión</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
      </Box>

      {/* Renderizado condicional basado en la vista actual */}
      {currentView === 'body' && (
        <>
        <div className="privacy-container">
          <h1 className="privacy-title">Términos y Condiciones</h1>
          <p>Bienvenido a Solution Business Agency LLC. Estos Términos y Condiciones rigen tu uso de nuestros servicios y el acceso a nuestro sitio web. Al utilizar nuestros servicios, aceptas los términos que aquí se exponen. Si no estás de acuerdo con estos términos, por favor no utilices nuestros servicios.</p>
          
          <h2>1. Definiciones</h2>
          <p><strong>"Nosotros" o "la empresa":</strong> Se refiere a Solution Business Agency LLC.</p>
          <p><strong>"Tú" o "el cliente":</strong> Se refiere a la persona que accede a nuestro sitio web o utiliza nuestros servicios.</p>
          <p><strong>"Servicios":</strong> Incluye todos los productos y servicios que ofrecemos, incluyendo asesoramiento, desarrollo de productos digitales y otros servicios tecnológicos.</p>
          <p><strong>"Plataforma de pagos":</strong> Stripe, el procesador de pagos utilizado por la empresa para gestionar transacciones.</p>
          
          <h2>2. Servicios Ofrecidos</h2>
          <p>Solution Business Agency LLC ofrece servicios de asesoramiento y desarrollo de productos digitales enfocados en ayudar a empresas pequeñas y medianas a mejorar su presencia en línea y eficiencia tecnológica.</p>
          <p><strong>Servicios Incluidos:</strong></p>
          <ul>
            <li>Consultoría en marketing digital y tecnología.</li>
            <li>Creación y desarrollo de productos digitales.</li>
            <li>Implementación de estrategias de marketing y publicidad.</li>
          </ul>
          <p>Nos reservamos el derecho de modificar o interrumpir, temporal o permanentemente, cualquier aspecto de nuestros servicios en cualquier momento sin previo aviso.</p>
          
          <h2>3. Proceso de Pagos</h2>
          <p>Utilizamos Stripe como nuestra plataforma de procesamiento de pagos. Al hacer uso de nuestros servicios que requieran un pago, aceptas los términos de uso de Stripe.</p>
          <p><strong>Moneda y Formas de Pago:</strong> Todos los precios se cotizan en USD, y los pagos se procesan en dicha moneda a través de Stripe. Stripe acepta varios métodos de pago, como tarjetas de crédito y débito.</p>
          <p><strong>Facturación y Pagos:</strong> El cliente acepta proporcionar información de pago válida y completa. Al proporcionar dicha información, autorizas a Solution Business Agency LLC y a Stripe a procesar los pagos correspondientes. Nos reservamos el derecho de suspender o cancelar tu acceso a los servicios si no se recibe el pago.</p>
          
          <h2>4. Reembolsos</h2>
          <p>Los pagos realizados por servicios prestados generalmente no son reembolsables. Sin embargo, consideraremos solicitudes de reembolso caso por caso, bajo nuestra exclusiva discreción, si el servicio prestado no cumple con lo prometido en el contrato o acuerdo.</p>
    
          <h2>5. Propiedad Intelectual</h2>
          <p>Todo el contenido, incluidos textos, gráficos, logotipos, imágenes, y cualquier otro material relacionado con los servicios proporcionados por Solution Business Agency LLC, es propiedad exclusiva de la empresa o de sus licenciantes.</p>
          <p>No tienes derecho a reproducir, distribuir, modificar o crear trabajos derivados de cualquier contenido sin nuestro consentimiento previo por escrito.</p>
    
          <h2>6. Uso Aceptable</h2>
          <p>Al utilizar nuestros servicios, te comprometes a:</p>
          <ul>
            <li>No utilizar nuestros servicios con fines ilegales o no autorizados.</li>
            <li>No acceder, manipular o utilizar cualquier parte de nuestros servicios de manera que cause daño o perjudique la seguridad del sitio o de los usuarios.</li>
            <li>No infringir los derechos de propiedad intelectual de terceros.</li>
          </ul>
          <p>Nos reservamos el derecho de suspender o cancelar tu acceso a nuestros servicios si se determina que has violado estos términos.</p>
    
          <h2>7. Limitación de Responsabilidad</h2>
          <p>En la máxima medida permitida por la ley, Solution Business Agency LLC no será responsable de ningún daño indirecto, incidental, especial o consecuente que surja del uso o la imposibilidad de usar nuestros servicios, incluyendo, entre otros, daños por pérdida de beneficios, pérdida de datos o interrupciones comerciales.</p>
    
          <h2>8. Modificaciones a los Términos</h2>
          <p>Podemos actualizar estos Términos y Condiciones periódicamente para reflejar cambios en nuestras prácticas o por otras razones operativas, legales o regulatorias. Las modificaciones serán efectivas a partir de la fecha de publicación en esta página.</p>
    
          <h2>9. Resolución de Disputas</h2>
          <p>Cualquier controversia o reclamación que surja de o en relación con estos términos será resuelta mediante arbitraje confidencial conforme a las reglas de arbitraje comercial de [jurisdicción correspondiente].</p>
    
          <h2>10. Ley Aplicable</h2>
          <p>Estos Términos y Condiciones se regirán e interpretarán de acuerdo con las leyes de Estados Unidos.</p>
    
          <h2>11. Contacto</h2>
          <p>Si tienes alguna pregunta o inquietud sobre estos Términos y Condiciones, por favor contáctanos a:</p>
          <p><strong>Solution Business Agency LLC</strong></p>
          <p><strong>Correo electrónico:</strong> info@solutionbusiness.tech</p>
          <p><strong>Teléfono:</strong> 59176811887</p>
        </div>
              <FooterComponent />
        </>
      )}
{currentView === 'login' && (
  <>
  <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/images/image20.png)',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'left',
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', // Centrado verticalmente
        }}
      >
        <Box
          sx={{
            padding: 4, // Ajustado para mejor visualización
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleLogin}>
            <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold' }}>
              Bienvenido
            </Typography>
            <Typography component="h1" variant="h6">
              Ingresa tu usuario y contraseña
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              placeholder="Usuario"
              name="user"
              autoComplete="user"
              autoFocus
              sx={{
                backgroundColor: '#000',
                borderRadius: '12px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fff',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              sx={{
                backgroundColor: '#000',
                borderRadius: '12px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fff',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
          control={
            <Checkbox 
              value="remember" 
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)} // Actualiza el estado del checkbox
              sx={{
                color: '#000', 
                '&.Mui-checked': {
                  color: '#000',
                },
              }} 
            />
          }
          label="Recordarme"
        />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                color: '#000',
                fontWeight: 'bold',
              }}
            >
              INGRESAR
            </Button>
          </Box>
          <Typography>¿Tienes problemas para ingresar?</Typography>
          <Typography>
            <Link href="#" variant="body2">
              info@solutionbusiness.tech
            </Link>
          </Typography>
          <Typography>
            <Link href="#" variant="body2">
              WhatsApp +1 000 00 000
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </>
)}
    </Container>
  );
};

export default TermsPage;
