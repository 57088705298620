// pages/PrivacyPage.js
import './styles.css'; // Importa el archivo de estilos
import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography, Button, AppBar, Toolbar, Menu, MenuItem } from '@mui/material';
import FooterComponent from '../components/FooterComponent';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import { db } from '../firebase';
import { collection, getDoc, doc, query, where, getDocs, addDoc  } from 'firebase/firestore';

const PrivacyPage = () => {
  const [currentView, setCurrentView] = useState('body');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [user, setUser] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const paramValue = queryParams.get('param');
    if (paramValue) {
      // Mostrar una alerta con el valor del parámetro
      alert(`Pago realizado por: ${paramValue} USD... Dentro de poco nos comunicaremos contigo`);
  
      // Convertir paramValue a número entero
      const paramValueAsNumber = parseInt(paramValue, 10);
  
      // Obtener la fecha actual
      const currentDate = new Date();
  
      // Verificar si hay un usuario en localStorage
      const storedUserDocId = localStorage.getItem('userDocId');
      if (storedUserDocId) {
        // Agregar un nuevo pago a la subcolección 'pagos' del usuario
        const addPayment = async () => {
          try {
            const userDocRef = doc(db, 'users', storedUserDocId);
            await addDoc(collection(userDocRef, 'pagos'), {
              amount: paramValueAsNumber,
              date: currentDate,
            });
            console.log('Pago agregado exitosamente a la subcolección.');
          } catch (error) {
            console.error('Error al agregar el pago:', error);
          }
        };
        addPayment();
      }
  
      // Eliminar el parámetro de la URL después de cerrar la alerta
      queryParams.delete('param');
      // Actualizar la URL sin recargar la página
      window.history.replaceState({}, document.title, window.location.pathname);
    }
  
    // Verificar si hay un usuario en localStorage
    const storedUserDocId = localStorage.getItem('userDocId');
    if (storedUserDocId) {
      // Cargar datos del documento de Firestore si existe el ID en localStorage
      const fetchUserData = async () => {
        try {
          const userDoc = await getDoc(doc(db, 'users', storedUserDocId));
          if (userDoc.exists()) {
            setUser(userDoc.data());
            setCurrentView('body');
          } else {
            alert('Usuario no encontrado, vuelva a iniciar sesión');
            localStorage.removeItem('userDocId');
          }
        } catch (error) {
          console.error('Error al obtener los datos del usuario:', error);
          alert('Error al obtener los datos del usuario.');
        }
      };
      fetchUserData();
    }
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Consultar la colección "users" para buscar una coincidencia
      const q = query(collection(db, 'users'), where('name', '==', email), where('pass', '==', password));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        // Si hay coincidencia, obtener el primer documento encontrado
        const userDoc = querySnapshot.docs[0];
        setUser(userDoc.data());

        if (rememberMe) {
          // Guardar la dirección (ID) del documento en localStorage
          localStorage.setItem('userDocId', userDoc.id);
        }

        setCurrentView('body');
      } else {
        alert('Usuario no encontrado, revisa tus datos.');
      }
    } catch (error) {
      console.error('Error al iniciar sesión:', error);
      alert('Error al iniciar sesión: ' + error.message);
    }
  };
  
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <Container disableGutters maxWidth={false} sx={{ padding: 0, margin: 0 }}>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{ backgroundColor: 'rgba(0, 0, 0, 0.9)' }}> {/* Fondo ligeramente transparente */}
        <Toolbar>
        <Typography 
          variant="h6" 
          component="div" 
          sx={{ flexGrow: 1, color: '#fff' }} // Cambia a azul neón en hover
          onClick={() => setCurrentView('body')}
        >
        Business Solution
        </Typography>
        <Box sx={{ flexGrow: 0 }}>
          <Typography 
            variant="h6" 
            component="div" 
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            sx={{ cursor: 'pointer', color: isHovered ? '#00BFFF' : '#fff' }}
            onClick={(event) => user ? handleOpenUserMenu(event) : setCurrentView('login')}
          >
            {user ? user.name : 'Portal Clientes'}
          </Typography>
          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            <MenuItem key='loginout' onClick={() => { 
              handleCloseUserMenu(); 
              localStorage.removeItem('userDocId');
              setUser(null);
            }}>
              <Typography textAlign="center">Cerrar sesión</Typography>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
      </Box>

      {/* Renderizado condicional basado en la vista actual */}
      {currentView === 'body' && (
        <>
        
    <div className="privacy-container">
      <h1 className="privacy-title">Política de Privacidad</h1>
      <p>Solution Business Agency LLC se compromete a proteger la privacidad de sus clientes. Esta Política de Privacidad describe cómo recopilamos, usamos y compartimos información cuando usas nuestros servicios, incluido el uso de Stripe como plataforma de procesamiento de pagos.</p>
      
      <p>Al utilizar nuestros servicios, aceptas los términos de esta Política de Privacidad. Si no estás de acuerdo con los términos aquí expuestos, por favor no utilices nuestros servicios.</p>
      
      <h2>1. Información que Recopilamos</h2>
      <h3>Información Personal</h3>
      <p>Podemos recopilar la siguiente información personal cuando interactúas con nuestros servicios o realizas un pago a través de Stripe:</p>
      <ul>
        <li>Nombre completo</li>
        <li>Dirección de correo electrónico</li>
        <li>Información de facturación (incluyendo dirección postal y número de teléfono)</li>
        <li>Información de la tarjeta de pago (recopilada y procesada de forma segura por Stripe)</li>
        <li>Cualquier otra información que decidas proporcionarnos voluntariamente.</li>
      </ul>

      <h3>Información de Uso</h3>
      <p>Podemos recopilar información sobre cómo accedes y utilizas nuestro sitio web o servicios, incluyendo:</p>
      <ul>
        <li>Dirección IP</li>
        <li>Tipo de navegador</li>
        <li>Páginas visitadas</li>
        <li>Tiempo de permanencia en las páginas</li>
        <li>Información sobre clics y enlaces</li>
      </ul>

      <h2>2. Uso de la Información</h2>
      <p>Usamos la información que recopilamos para los siguientes propósitos:</p>
      <ul>
        <li>Procesar pagos de manera segura a través de Stripe.</li>
        <li>Comunicarnos contigo en relación con pedidos, productos o servicios.</li>
        <li>Proporcionar soporte técnico y atención al cliente.</li>
        <li>Cumplir con nuestras obligaciones legales.</li>
        <li>Mejorar nuestros servicios y personalizar la experiencia del usuario.</li>
      </ul>

      <h2>3. Uso de Stripe para Procesamiento de Pagos</h2>
      <p>Utilizamos Stripe, un proveedor de servicios de pago de terceros, para gestionar las transacciones de pago. Al realizar un pago, tu información de pago se transmite directamente a Stripe, que la utiliza de acuerdo con su propia política de privacidad.</p>
      
      <p>Stripe cumple con los estándares de seguridad de la industria, como PCI-DSS, lo que garantiza que tu información de pago sea procesada de forma segura. No almacenamos ni tenemos acceso a la información completa de tu tarjeta de crédito.</p>
      
      <p>Puedes consultar la <a href="https://stripe.com/privacy" target="_blank" rel="noopener noreferrer">Política de Privacidad de Stripe</a> para obtener más información sobre cómo gestionan tus datos personales.</p>

      <h2>4. Cómo Compartimos tu Información</h2>
      <p>No vendemos ni alquilamos tu información personal a terceros. Sin embargo, podemos compartir tu información en las siguientes circunstancias:</p>
      <ul>
        <li>Con Stripe para procesar pagos.</li>
        <li>Con proveedores de servicios de terceros que nos ayudan a operar nuestro negocio, como hosting o analítica web.</li>
        <li>Cuando sea requerido por ley, o para proteger nuestros derechos legales.</li>
      </ul>

      <h2>5. Seguridad de la Información</h2>
      <p>Tomamos medidas razonables para proteger la información personal contra accesos no autorizados, uso o divulgación. Sin embargo, no podemos garantizar la seguridad de los datos transmitidos a través de Internet.</p>

      <h2>6. Tus Derechos</h2>
      <p>Tienes derecho a:</p>
      <ul>
        <li>Acceder, corregir o eliminar tu información personal.</li>
        <li>Retirar tu consentimiento para el procesamiento de tus datos en cualquier momento.</li>
        <li>Presentar una queja ante la autoridad de protección de datos correspondiente.</li>
      </ul>
      <p>Para ejercer estos derechos, puedes contactarnos a través del correo electrónico: <strong>info@solutionbusiness.tech</strong></p>

      <h2>7. Cambios en la Política de Privacidad</h2>
      <p>Nos reservamos el derecho de modificar esta Política de Privacidad en cualquier momento. Cualquier cambio será notificado en esta página con la fecha de la última actualización.</p>

      <h2>8. Contacto</h2>
      <p>Si tienes preguntas sobre esta Política de Privacidad o sobre cómo manejamos tu información personal, puedes contactarnos a:</p>
      <p><strong>Solution Business Agency LLC</strong></p>
      <p><strong>Correo electrónico:</strong> info@solutionbusiness.tech</p>
      <p><strong>Teléfono:</strong> 59176811887</p>
    </div>
              <FooterComponent />
        </>
      )}
{currentView === 'login' && (
  <>
  <Grid container component="main" sx={{ height: '100vh' }}>
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: 'url(/images/image20.png)',
          backgroundColor: (t) =>
            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
          backgroundSize: 'cover',
          backgroundPosition: 'left',
        }}
      />
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={6}
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center', // Centrado verticalmente
        }}
      >
        <Box
          sx={{
            padding: 4, // Ajustado para mejor visualización
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleLogin}>
            <Typography component="h1" variant="h5" sx={{ fontWeight: 'bold' }}>
              Bienvenido
            </Typography>
            <Typography component="h1" variant="h6">
              Ingresa tu usuario y contraseña
            </Typography>
            <TextField
              margin="normal"
              required
              fullWidth
              id="user"
              placeholder="Usuario"
              name="user"
              autoComplete="user"
              autoFocus
              sx={{
                backgroundColor: '#000',
                borderRadius: '12px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fff',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              sx={{
                backgroundColor: '#000',
                borderRadius: '12px',
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderRadius: '12px',
                  },
                  '&:hover fieldset': {
                    borderColor: '#fff',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#fff',
                  },
                },
                '& .MuiInputBase-input': {
                  color: 'white',
                },
              }}
              InputLabelProps={{
                style: { color: '#fff' },
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <FormControlLabel
          control={
            <Checkbox 
              value="remember" 
              checked={rememberMe}
              onChange={(e) => setRememberMe(e.target.checked)} // Actualiza el estado del checkbox
              sx={{
                color: '#000', 
                '&.Mui-checked': {
                  color: '#000',
                },
              }} 
            />
          }
          label="Recordarme"
        />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                color: '#000',
                fontWeight: 'bold',
              }}
            >
              INGRESAR
            </Button>
          </Box>
          <Typography>¿Tienes problemas para ingresar?</Typography>
          <Typography>
            <Link href="#" variant="body2">
              info@solutionbusiness.tech
            </Link>
          </Typography>
          <Typography>
            <Link href="#" variant="body2">
              WhatsApp +1 000 00 000
            </Link>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  </>
)}
    </Container>
  );
};

export default PrivacyPage;
