// Importa BrowserRouter, Routes y Route
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/home.js';
import PrivacyPage from './pages/privacy.js';
import TermsPage from './pages/tyc.js';
import SupportPage from './pages/support.js';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/tyc" element={<TermsPage />} />
        <Route path="/support" element={<SupportPage />} />
      </Routes>
    </Router>
  );
}

export default App;
